import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
// styles
import contactFormStyle from "assets/jss/material-kit-react/components/contactFormStyle.jsx";

class ContactForm extends React.Component {
  render() {
    const {
      classes,
      formName
    } = this.props;
    
    return (
        <form name={formName} method="post" data-netlify="true" data-netlify-recapthca="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value={formName} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Your Name"
              id="name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: true,
                name: "name",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Your Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                required: true,
                name: "email",
                type: "email",
              }}
            />
          </GridItem>
          <CustomInput
            labelText="Your Message"
            id="message"
            formControlProps={{
              fullWidth: true,
              className: classes.textArea
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              required: true,
              name: "message",
            }}
          />
          <GridItem>
            <ReCAPTCHA sitekey="6Le0pMoUAAAAAKHAbEwjkpkANXEzOEvo0bANGqkJ" />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
          >
            <Button type="submit" color="primary">Send Message</Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(contactFormStyle)(ContactForm);
